import flowerImg from '../assets/images/hawaiianFlowersImg.png';

const Specials = () => {
  return (
    <div className="specialsContainer">
      <h3>Kama'āina Specials</h3>
      <div className="mci-app-node mci-idx-node mci-idx-node-search" data-mod="search" data-display="featured"><div style={{textAlign: "center"}}>Loading...</div></div>
      <h3>Kama'āina Specials Information:</h3>
      <div className="specialsInfoDiv">
        <img src={flowerImg} alt="hawaiian flower bouqet"></img>
        <ul style={{listStyleType: 'none'}}>
          <li>3.5% selling commission</li>
          <li>Our staff here at MLB will give all the necessary information needed</li>
          <li>Contact us for more information:
            <a href="tel:8088702123"> (808) 870-2123</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Specials;
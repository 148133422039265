import Hero from '../components/Hero';
import Cards from '../components/Cards';

const Home = () => {
  return (
    <div className="homeContainer">
      <Hero />
      <Cards />
    </div>
  )
}

export default Home;
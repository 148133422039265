import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import MLBlogo from '../assets/images/MLBlogo.png';


function NavigationBar() {

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary" fixed="top">
        <Container>
          <Navbar.Brand href="/">
            <img className="navImg" alt="logo" src={MLBlogo}></img>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="responsive-navbar-nav" className="flex-column">
            <Nav className="ms-auto fs-3" style={{gap: '2.5rem'}}>
              {/* <Nav.Link href="/associations">Associations</Nav.Link> */}
              <NavDropdown title="Services">
                <NavDropdown.Item className="navDropdownItem fs-5" href="/buysell">Buy/Sell With Us</NavDropdown.Item>
                <NavDropdown.Item className="navDropdownItem fs-5" href="/rentwithus">Rent With Us</NavDropdown.Item>
                <NavDropdown.Item className="navDropdownItem fs-5" href="/mortgagecalculator">Mortgage Calculator</NavDropdown.Item>
                {/* <NavDropdown.Item className="navDropdownItem fs-5" href="/hoainfo">HOA Info.</NavDropdown.Item> */}
                <NavDropdown.Item className="navDropdownItem fs-5" href="/specials">Kama'āina Specials</NavDropdown.Item>
                <NavDropdown.Item className="navDropdownItem fs-5" target="_blank" href="https://www.panabodehomes.com/">Cedar Homes</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/searchresults">Listings</Nav.Link>
              <Nav.Link href="/agents">Agents</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;

const SearchResults = () => {
  return (
    <div className="searchResultsContainer">
      <h3>Maui Real Estate Search</h3>
      <div className="mci-app-node mci-idx-node mci-idx-node-search" data-mod="search" data-display="splash">
        <div style={{textAlign: "center"}}>Loading...</div>
      </div>
    </div>
  )
}

export default SearchResults;